/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import firebase from "firebase"
import {firebaseInit} from './src/config'
require('firebase/auth')

export const onClientEntry = ()=> {
    firebaseInit(firebase)
}
